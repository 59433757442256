<template>
  <div class="page1">
    <!--面包屑-->
    <!-- <div class="breadcrumb"> -->
    <!--<el-breadcrumb separator="/">
                <el-breadcrumb-item>商户管理</el-breadcrumb-item>
                <el-breadcrumb-item>商户信息管理</el-breadcrumb-item>
            </el-breadcrumb>-->
    <!-- </div> -->
    <!--主体内容-->
    <div class="content bgFFF">
      <h2 class="title">
        <div class="title_icon"></div>
        {{ isEdit ? "修改" : "添加" }}人员
      </h2>
      <!--搜索条件区域-->
      <div>
        <el-form
          label-position="right"
          label-width="110px"
          :model="formInline"
          style="width: 330px"
          :rules="rules"
          ref="form"
        >
          <el-form-item :label="$t('searchModule.full_name')" prop="pdaManagerName">
            <el-input
              :maxlength="10"
              v-model.trim="formInline.pdaManagerName"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.sex')" prop="sex">
            <el-radio-group v-model.trim="formInline.sex" filterable placeholder="">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="0">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="出生年份" prop="birthday">
            <el-date-picker
              v-model="formInline.birthday"
              type="year"
              placeholder="选择年份"
              value-format="yyyy"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Employee_ID')" prop="pdaManagerCode">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.pdaManagerCode"
              placeholder="输入员工编号"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.phone_number')" prop="mobile">
            <el-input
              :maxlength="11"
              v-model.trim="formInline.mobile"
              placeholder="输入手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.PDA_account')" prop="account">
            <el-input
              :maxlength="20"
              :disabled="isEdit"
              v-model.trim="formInline.account"
              placeholder="输入PDA账号"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="PDA密码" prop="password" v-if='!isEdit'>
            <el-input :maxlength='20' v-model.trim="formInline.password" placeholder="输入PDA密码"></el-input>
          </el-form-item> -->
          <el-form-item :label="$t('searchModule.On_duty_status')" prop="pdaManagerState" style="display: none">
            <el-select v-model="formInline.pdaManagerState">
              <el-option
                :label="value.name"
                :value="value.code"
                v-for="value in allManageState"
                :key="value.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Merchant_affiliation')" prop="operationId">
            <el-select
              :disabled="isEdit"
              @change="queryParkList"
              v-model.trim="formInline.operationId"
              filterable
            >
              <!-- <el-option label="全部"
                         value=""></el-option> -->
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in tenantList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Department_to')" prop="deptId">
            <el-cascader
              :options="treeData"
              expand-trigger="click"
              filterable
              clearable
              :show-all-levels="false"
              v-model="formInline.deptId"
              :props="defaultProps"
              change-on-select
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item :label="$t('searchModule.role')" prop="pdaManagerRole">
            <el-select v-model.trim="formInline.pdaManagerRole" filterable size="12">
              <el-option label="请选择" value></el-option>
              <el-option
                :label="value.name"
                :value="value.code"
                :key="value.code"
                v-for="value in pdaList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Choose_parking_lot')" class="selected" style="width: 700px" prop="parkIds">
            <el-transfer
              :titles="['未选择', '已选择']"
              filterable
              :props="{
                key: 'parkId',
                label: 'parkName',
              }"
              filter-placeholder="请输入车场名称"
              v-model="selected"
              @change="(val) => changeTransferFn(val)"
              :data="parkList"
            >
            </el-transfer>
            <div class="el-form-item__error" v-if="isSelected">请选择一个停车场</div>
          </el-form-item>
          <el-form-item
            label=""
            style="width: calc(100vw - 340px)"
            v-show="showParkingList.length > 0"
          >
            <ul style="padding: 8px; border: 1px solid #dcdcdc; width: 100%">
              <li v-for="(item, index) in showParkingList" :key="index">
                <div class="showParkListBox">
                  <div class="parentName">{{ item[0].parentName }}:</div>
                  <div class="showChildBox">
                    <!-- @click="changeParentParking(index, subItem)" -->
                    <!-- :class="{
                        'is-disabled': subItem.parentId == '0',
                        'is-checked': subItem.checked
                      }" -->
                    <div
                      class="el-checkbox__input checkBox"
                      v-for="(subItem, sIndex) in item"
                      :key="sIndex"
                    >
                      <!-- <span class="el-checkbox__inner"></span> -->
                      <!-- <el-checkbox
                        v-model="subItem.checked"
                        @change="changeParentParking(index, JSON.stringify(subItem));"
                        ></el-checkbox
                      > -->
                      <el-checkbox
                        @change="handleCheckAllChange(subItem, 0, item)"
                        v-model="subItem.checked"
                        :disabled="
                          subItem.parentId == '0' ||
                          (subItem.countSize == 1 && subItem.checked) ||
                          subItem.disableAll
                        "
                      ></el-checkbox>
                      <span
                        v-show="subItem.parkName.length > 12"
                        :title="subItem.parkName"
                        :class="{
                          labelDisabled:
                            (subItem.checked && subItem.parentId == '0') ||
                            (subItem.countSize == 1 && subItem.checked) ||
                            subItem.disableAll,
                          labelChecked:
                            subItem.countSize != 1 &&
                            subItem.checked &&
                            subItem.parentId != '0' &&
                            !subItem.disableAll,
                        }"
                        class="checkLabel"
                        >{{ subItem.parkName }}</span
                      >
                      <span
                        v-show="subItem.parkName.length < 12 || subItem.parkName.length == 12"
                        :title="subItem.parkName"
                        :class="{
                          labelDisabled:
                            (subItem.checked && subItem.parentId == '0') ||
                            (subItem.countSize == 1 && subItem.checked) ||
                            subItem.disableAll,
                          labelChecked:
                            subItem.countSize != 1 &&
                            subItem.checked &&
                            subItem.parentId != '0' &&
                            !subItem.disableAll,
                        }"
                        class="checkLabel"
                        >{{ subItem.parkName }}</span
                      >

                      <div class="child-box-wrap">
                        <!-- <div
                          class="el-checkbox__input checkBox checkbox-parent checkbox-wrapper"
                          v-for="(subItem, sIndex) in item"
                          :key="sIndex"
                        > -->
                        <el-checkbox
                          :indeterminate="subItem.isIndeterminate"
                          @change="handleCheckAllChange(subItem, 1, item)"
                          v-model="subItem.checkAll"
                          v-if="subItem.berths && subItem.berths.length > 0"
                          :disabled="subItem.disableAll"
                          >全选</el-checkbox
                        >
                        <el-checkbox-group
                          v-model="subItem.checkedBerths"
                          :min="1"
                          @change="handleCheckedCitiesChange(subItem, subItem.checkedBerths, item)"
                          class="child-item"
                        >
                          <el-checkbox
                            v-for="berthItem in subItem.berths"
                            :label="berthItem.berthId"
                            :key="berthItem.berthId"
                            :disabled="subItem.disableAll"
                            >{{ berthItem.berthCode }}</el-checkbox
                          >
                        </el-checkbox-group>
                        <!-- </div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="child-box-wrap">
                  <div
                    class="el-checkbox__input checkBox checkbox-parent checkbox-wrapper"
                    v-for="(subItem, sIndex) in item"
                    :key="sIndex"
                  >
                    <el-checkbox
                      :indeterminate="subItem.isIndeterminate"
                      @change="handleCheckAllChange(subItem)"
                      v-model="subItem.checkAll"
                      v-if="subItem.berths && subItem.berths.length > 0"
                      >全选</el-checkbox
                    >
                    <el-checkbox-group
                      v-model="subItem.checkedBerths"
                      :min="1"
                      @change="handleCheckedCitiesChange(subItem, subItem.checkedBerths)"
                      class="child-item"
                    >
                      <el-checkbox
                        v-for="berthItem in subItem.berths"
                        :label="berthItem.berthId"
                        :key="berthItem.berthId"
                        >{{ berthItem.berthCode }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                </div> -->
              </li>
            </ul>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Items_received')" prop="assetsIds">
            <el-select v-model="assetsIds" multiple filterable @change="setAssetsIds">
              <!-- <el-option label="全部"
                         value=""></el-option> -->
              <el-option
                :label="data.assetsName"
                :value="data.assetsId"
                v-for="data in assetList"
                :key="data.assetsId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div style="text-align: left; margin-left: 8.5%; margin-top: 40px">
          <el-button type="primary" @click="submitData" style="width: 88px">提交</el-button>
          <el-button type="" @click="$router.go(-1)" style="width: 88px">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import //  dateFormat
"@/common/js/public.js";
var regEn = new RegExp(
  "[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
);
export default {
  name: "hello",
  data() {
    let checkCode = (rule, value, callback) => {
      if (/[\u4E00-\u9FA5]/.test(value)) {
        return callback(new Error("员工编号不能存在汉字"));
      }
      if (value) {
        if (value.length < 2 || value.length > 50) {
          return callback(new Error("员工编号长度需在2-50个字符之内"));
        }
        if (regEn.test(value)) {
          callback(new Error("员工编号不能存在特殊符号"));
        }
        if (/[a-zA-Z0-9]{2,50}/.test(value)) {
          // if (/^[0-9A-Za-z]+$/.test(value)) {
          callback();
          // this.request(opt);
        } else {
          callback(new Error("员工编号包含字母和数字"));
        }
      } else {
        callback(new Error("员工编号不能为空"));
      }
    };
    let checkPDAccount = (rule, value, callback) => {
      if (value) {
        if (regEn.test(value)) {
          callback(new Error("PDA账号不能存在特殊符号"));
        }
        if (/\w{2,50}/.test(value) && !/^\d{2,50}$/.test(value)) {
          let url = "/acb/2.0/pdaManager/checkAccount/" + value;
          this.$axios.get(url).then((res) => {
            if (res.value || (!res.value && this.oldData["account"] == value)) {
              callback();
            } else {
              callback(new Error("PDA账号已存在"));
            }
          });
        } else {
          callback(new Error("PDA账号2-50个字符且不能为纯数字"));
        }
      } else {
        callback(new Error("PDA账号不能为空"));
      }
    };
    let setBirth = (rule, value, callback) => {
      let cur = new Date().getFullYear();
      if (parseInt(value) != value) {
        callback(new Error("出生年份格式不正确"));
      } else if (value > cur) {
        callback(new Error("出生年份不能大于当前年份"));
      } else if (!/^\d{4}$/.test(value)) {
        callback(new Error("出生年份必须为4位数"));
      } else {
        callback();
      }
    };
    let checkName = (rule, value, callback) => {
      let len = value.length;
      for (let i = 0; i < len; i++) {
        if (value.charCodeAt(i) < 10000) {
          callback(new Error("姓名必须为汉字"));
          return;
        }
      }
      callback();
    };
    let checkPsd = (rule, value, callback) => {
      if (/\w{2,50}/.test(value)) {
        callback();
      } else {
        callback(new Error("密码必须为2-50个字符"));
      }
    };
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value)) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    return {
      assetsNames: [], // 资产名称集合
      pdaList: [],
      treeData: [],
      defaultProps: {
        children: "childNodes",
        label: "deptName",
        value: "deptId",
      },
      countSize: 0, // 父车场选中个数
      checkAll: true,
      checkedBerthsList: [],
      isSelected: false, // 判断选择车场
      selected: [],
      parkList: [],
      oldAccount: "",
      oldassetsIds: "",
      oldCode: "",
      allManageState: [],
      oldData: {},
      assetList: [],
      sexList: [],
      isEdit: false,
      loading: false,
      detailFlag: false,
      checkList: [],
      tenantList: [],
      oldOperationId: "",
      formInline: {
        pdaManagerName: "",
        sex: 1,
        birthday: "",
        pdaManagerCode: "",
        mobile: "",
        account: "",
        password: "",
        pdaManagerState: 1,
        parkIds: [],
        berthIds: [],
        assetsIds: "",
        operationId: "",
        deptId: "",
        pdaManagerRole: "",
      },
      assetsIds: "", // 资产id集合
      rules: {
        pdaManagerRole: [
          {
            required: true,
            message: "请选择角色",
            trigger: "blur",
          },
        ],
        deptId: [
          {
            required: true,
            message: "请选择所属部门",
            trigger: "blur",
          },
        ],
        operationId: [
          {
            required: true,
            message: "请选择商户",
            trigger: "blur",
          },
        ],
        pdaManagerName: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
          { validator: checkName, trigger: "blur" },
        ],
        sex: [
          {
            required: true,
            message: "请选择性别",
            trigger: "blur",
          },
        ],
        birthday: [
          {
            required: true,
            message: "请选择年份",
            trigger: "blur",
          },
          { validator: setBirth, trigger: "blur" },
        ],
        pdaManagerCode: [
          {
            required: true,
            message: "请输入员工编号",
            trigger: "blur",
          },
          { validator: checkCode, trigger: "blur" },
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          { validator: checkPhone, trigger: "blur" },
        ],
        account: [
          {
            required: true,
            message: "请输入PDA账号",
            trigger: "blur",
          },
          { validator: checkPDAccount, trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          { validator: checkPsd, trigger: "blur" },
        ],
        pdaManagerState: [
          {
            required: true,
            message: "请选择员工状态",
            trigger: "blur",
          },
        ],
      },
      showParkingList: [],
      showSelect: [],
      showBerthSelect: [],
      flagValue: false,
    };
  },
  methods: {
    // 商户名称选择
    // getOperationInfo(val) {
    //   this.formInline.operationId = val;
    //   this.getByCurrentUser(val);
    // },
    // 角色下拉框
    pdachange() {
      this.$axios.get("/acb/2.0/pdaManager/spinner").then((res) => {
        if (res.state == 0) {
          this.pdaList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 获取所属部门
    getByCurrentUser(val) {
      this.$axios.get("/acb/2.0/dept/getDeptTreeByOperationId?operationId=" + val).then((res) => {
        if (res.value) {
          this.treeData = [];
          this.treeData.push(res.value);
          this.treeData = [res.value];
        }
      });
    },
    handleChange(value) {
      if (value.length) {
        this.formInline.deptId = value[value.length - 1];
      }
    },
    openPark() {
      this.$refs.chosePark.open();
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 获取管理员状态
    getAllManageState() {
      let url = "/acb/2.0/pdaManager/jobState/list";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.allManageState = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    workerStateChange(d) {
      if (d != 1) {
        this.disab = true;
        this.formInline.parkId = "0";
        this.rules.parkId = [];
      } else {
        this.disab = false;
        this.rules.parkId = [
          {
            required: true,
            message: "请选择停车场",
            trigger: "blur",
          },
          { validator: this.checkPark, trigger: "blur" },
        ];
      }
    },
    checkPark(rule, value, callback) {
      if (value == 0) {
        callback(new Error("请选择一个停车场"));
      } else {
        callback();
      }
    },
    // 查询车场列表
    queryParkList(val) {
      this.formInline.operationId = val;
      this.getByCurrentUser(val);
      this.selected = [];
      // 切换商户清空子泊位
      this.showParkingList = [];
      if (!this.formInline.operationId) {
        this.formInline.assetsIds = "";
        return;
      }
      this.queryAsset();
      let url =
        "/acb/2.0/systems/loginUser/parkNameList?parkSystemType=0?slaveRelations=0,1&parkTypes=1,2,4&operationId=" +
        this.formInline.operationId;
      this.$axios.get(url).then((res) => {
        // console.log(res)
        // console.log(typeof res)
        if (res.state != 0) {
          this.$alert(res.desc);
          return;
        }
        this.parkList = res.value;
      });
    },
    // 选择
    changeParentParking(index, item) {
      // console.log('父：', index + '---' + item)
      item = JSON.parse(item);
      if (item.parentId == "0") {
        return;
      }
      // 如果取消选中  最后一个子车场需要弹框提示
      if (item.checked == true) {
        this.$set(item, "checked", false);
        if (this.checkChildNum(index).length == 0) {
          this.$message({
            message: "每个父车场至少保留一个子车场哦",
            duration: 2000,
          });
          this.$set(item, "checked", true);
        }
      } else {
        this.$set(item, "checked", true);
        item.checked = false;
        item.checkAll = false;
        item.checkedBerths = [];
        item.berthIds = [];
      }
    },
    // 检查是否是最后一个车场
    checkChildNum(index) {
      return this.showParkingList[index].filter((item) => {
        return item.checked == true;
      });
    },
    // 选择父车场或独立车场
    changeTransferFn(value, isEdit) {
      this.selectedPark();
      let parkIds = value.join(",");
      // this.$axios['get']('/acb/2.0/park/getChildByParkIds', {
      //   data: {
      //     parkIds: parkIds
      //   }
      // }).then(res => {
      //   let arr = [];
      //   for (var i in res.value) {
      //     if (res.value[i] && res.value[i].length > 0) {
      //       res.value[i][0].parentName = i;
      //       res.value[i].forEach(item => {
      //         item.checked = true;
      //         if (this.showParkingList.length > 0) {
      //           this.showParkingList.map(showItem => {
      //             showItem.map(showSubItem => {
      //               if (showSubItem.parkId == item.parkId) {
      //                 item.checked = showSubItem.checked
      //               }
      //             })
      //           })
      //         } else {
      //           // 如果是编辑 只有上次选中的是勾选状态
      //           if (isEdit) {
      //             item.checked = false
      //             this.showSelect.map(selectId => {
      //               if (selectId == item.parkId) {
      //                 item.checked = true
      //               }
      //             })
      //           } else {
      //             item.checked = true;
      //           }
      //         }
      //       });
      //       arr.push(res.value[i])
      //     }
      //   }
      //   this.showParkingList = arr;
      // })
      const data = new FormData();
      data.append("parkIds", parkIds);
      const opt = {
        url: "/acb/2.0/park/getChildBerthByParkIds",
        method: "post",
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        data: data,
        success: (res) => {
          let arr = [];
          for (var i in res.value) {
            let count = 0;
            if (res.value[i] && res.value[i].length > 0) {
              res.value[i][0].parentName = i;
              res.value[i].forEach((item) => {
                item.checked = true;
                // if (this.showParkingList.length > 0) {
                //   this.showParkingList.map((showItem) => {
                //     showItem.map((showSubItem) => {
                //       if (showSubItem.parkId == item.parkId) {
                //         item.checked = showSubItem.checked;
                //       }
                //     });
                //   });
                // } else {
                // 如果是编辑 只有上次选中的是勾选状态
                if (isEdit) {
                  item.checked = false;
                  this.showSelect.map((selectId) => {
                    if (selectId == item.parkId) {
                      item.checked = true;
                      if (item.parentId != "0") {
                        count += 1;
                      }
                    }
                  });
                  this.countSize = count;
                  item.countSize = count;
                  item.checkedBerths = [];
                  item.berthIds = [];
                  this.checkedBerthsList.map((selectItem) => {
                    item.berths &&
                      item.berths.forEach((el) => {
                        if (selectItem.includes(el.berthId)) {
                          item.checkedBerths.push(el.berthId);
                          item.berthIds.push(el.berthId);
                        }
                      });
                  });

                  // item.berths && item.berths.forEach((el) => {
                  //   item.checkedBerths.push(el.berthId);
                  //   item.berthIds.push(el.berthId);

                  // this.checkedBerthsList.map((selectItem) => {
                  //   if (selectItem == el.berthId) {
                  //     el.checked = true;
                  //   }
                  // });
                  // });
                  item.checkAll =
                    (item.berthIds && item.berthIds.length) === (item.berths && item.berths.length);
                  item.isIndeterminate =
                    (item.berths && item.berths.length) > (item.berthIds && item.berthIds.length) &&
                    item.berthIds.length > 0;
                } else {
                  item.berthIds = [];
                  item.checkedBerths = [];
                  if (this.checkedBerthsList.length > 0) {
                    this.checkedBerthsList.map((selectItem) => {
                      item.berths &&
                        item.berths.forEach((el) => {
                          if (selectItem.includes(el.berthId)) {
                            item.checkedBerths.push(el.berthId);
                            item.berthIds.push(el.berthId);
                          }
                        });
                    });
                  } else {
                    item.berths &&
                      item.berths.forEach((el) => {
                        item.berthIds.push(el.berthId);
                        item.checkedBerths.push(el.berthId);
                      });
                  }
                  item.isIndeterminate =
                    (item.berthIds && item.berthIds.length) < (item.berths && item.berths.length);
                  item.checkAll =
                    (item.berthIds && item.berthIds.length) === (item.berths && item.berths.length);
                  res.value[i].forEach((current) => {
                    if (current.parentId != "0") {
                      count += 1;
                    }
                  });
                  this.countSize = count;
                  item.countSize = count;
                }
                if (item.disableEdit == 0) {
                  item.disableAll = false;
                } else {
                  item.disableAll = true;
                }
                // }
              });
              arr.push(res.value[i]);
            }
          }
          this.showParkingList = arr;
          console.log("showParkingList", this.showParkingList);
        },
      };
      this.$request(opt);
    },
    addList() {
      let url = this.isEdit ? "/acb/2.0/pdaManager/update" : "/acb/2.0/pdaManager/create";
      let method = this.isEdit ? "post" : "post";
      this.$axios[method](url, {
        data: this.formInline,
      }).then((res) => {
        if (res.state != 0) {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        this.$router.go(-1);
      });
    },
    queryAsset() {
      let data = {};
      data.operationId = this.formInline.operationId;
      data.status = 0;
      if (this.formInline.operationId === this.oldOperationId) {
        // data.assetsIds = this.oldassetsIds;
      } else {
        this.formInline.assetsIds = "";
        this.assetsIds = [];
      }
      let url = "/acb/2.0/assets/allList";
      this.$axios
        .get(url, {
          data: data,
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          if (this.$route.query.pdaManagerId && this.assetsIds.length > 0) {
            console.log(this.assetsIds, "*****");
            let arr = [];
            this.assetsIds.forEach((ele, index) => {
              arr.push({
                assetsId: ele,
                assetsName: this.assetsNames[index],
              });
            });
            this.assetList = arr.concat(res.value.list);
          } else {
            this.assetList = res.value.list;
          }
        });
    },
    // 设置所辖资产
    setAssetsIds() {
      // console.log(this.assetsIds, this.formInline.assetsIds);
      this.formInline.assetsIds = this.assetsIds.join(",");
    },
    workDetail() {
      let url = "/acb/2.0/pdaManager/get/" + this.formInline.pdaManagerId;
      this.$axios.get(url).then((res) => {
        if (res.state != 0) {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        this.oldData = res.value;
        for (let i in this.formInline) {
          this.formInline[i] = res.value[i];
        }
        this.formInline.assetsIds = !this.formInline.assetsIds ? "" : this.formInline.assetsIds;
        // this.oldassetsIds = this.formInline.assetsIds;
        this.assetsIds = !this.formInline.assetsIds ? [] : this.formInline.assetsIds.split(",");
        this.assetsNames = res.value.assetsName ? res.value.assetsName.split(",") : [];
        this.oldOperationId = this.formInline.operationId;
        this.queryParkList(this.oldData.operationId);
        // this.selected = this.formInline['parkIds'].split(',');
        let selectArr = [];
        res.value.parentparkList.map((item) => {
          if (item.parentId == "0") {
            selectArr.push(item.parkId);
          } else {
            selectArr.push(item.parentId);
          }
        });
        this.selected = Array.from(new Set(selectArr));
        this.showSelect = res.value.parkIds.split(",");
        // 选中的泊位号
        this.showBerthSelect = res.value.berthIds;
        this.checkedBerthsList = res.value.berthIds;
        this.changeTransferFn(this.selected, true);
      });
    },
    getCheckedIds() {
      let res = [];
      this.showParkingList.map((item) => {
        item.map((subItem) => {
          if (subItem.checked) {
            res.push(subItem.parkId);
          }
        });
      });
      return res;
    },

    getCheckedBerthIds() {
      let res = [];
      this.showParkingList.map((item) => {
        item.map((subItem) => {
          subItem.checkedBerths.forEach((val) => {
            res.push(val);
          });
          // if (subItem.berths && subItem.berths.length > 0 && subItem.checkedBerths.length == 0) {
          //   this.$message({
          //     message: subItem.parkName + '子车场至少选择一个泊位',
          //     type: 'warning'
          //   });
          //   this.flagValue = true;
          // } else {

          // }
        });
      });
      return res;
    },
    // 判断选择车场
    selectedPark() {
      if (this.selected.length > 0) {
        this.isSelected = false;
      } else {
        this.isSelected = true;
      }
    },
    submitData() {
      this.selectedPark();
      this.formInline.parkIds = this.getCheckedIds().join(",");
      this.formInline.berthIds = this.getCheckedBerthIds().join(",");
      console.log(this.formInline);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.formInline.deptId == "") {
            this.$alert("请选择所属部门！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }
          if (this.formInline.parkIds == "") {
            this.$alert("请选择停车场！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }
          if (this.formInline.berthIds == "") {
            this.$alert("请选择泊位！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }

          this.addList();
        } else {
          return false;
        }
      });
    },

    handleCheckAllChange(data, index, item) {
      let count = 0;
      item.forEach((el) => {
        if (el.checked == true) {
          count += 1;
        }
      });
      item.forEach((vv) => {
        vv.countSize = count;
      });
      // index 0：父车场  1：子车场
      if (index == 0) {
        if (data.checked == false) {
          data.checked = data.checked;
          data.checkAll = false;
          data.isIndeterminate = false;
        } else {
          data.checked = data.checked;
          data.checkAll = true;
          data.isIndeterminate = true;
        }
      }
      data.berthIds = [];
      data.checkAll
        ? data.berths.forEach((item) => {
            if (!data.berthIds.includes(item.berthId)) {
              data.berthIds.push(item.berthId);
              // data.isIndeterminate = false;
            }
          })
        : data.berthIds.splice(0);
      data.isIndeterminate = data.berths.length < data.berthIds.length;
      data.checkedBerths = data.berthIds;
    },

    handleCheckedCitiesChange(p1, value, item) {
      p1.berthIds = [];
      // p1.berths.forEach(val => {
      //   p1.berthIds.push(val.berthId);
      // })
      p1.checked = true;
      let count = 0;
      item.forEach((el) => {
        if (el.checked == true) {
          count += 1;
        }
      });
      p1.countSize = count;
      p1.berthIds = value;
      p1.checkedBerths = value;
      let checkedCount = value.length;
      p1.checkAll = checkedCount === p1.berths.length;
      p1.isIndeterminate = checkedCount > 0 && checkedCount < p1.berths.length;
    },
  },
  components: {},
  created() {
    this.getTenantList();
    this.getAllManageState();
    this.pdachange();
    // this.getByCurrentUser();
  },
  mounted() {
    if (this.$route.query.pdaManagerId) {
      setTimeout(() => {
        this.formInline.pdaManagerId = this.$route.query.pdaManagerId;
        this.formInline.operationId = this.$route.query.operationId;
        this.isEdit = true;
        this.workDetail();
      }, 150);
    }
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;
}

.child-box-wrap {
  display: flex;
  // flex-direction: row;
  align-items: flex-start;
  padding-left: 20px;
  padding-top: 10px;

  .checkbox-parent {
    align-items: flex-start;
  }

  .checkbox-wrapper {
    display: inline-block;
  }

  .child-item {
    white-space: normal;
    padding-left: 20px;
    line-height: 20px;
  }
}
</style>
<style scoped>
.chosePark {
  float: right;
  margin-right: -70px;
  margin-top: -52px;
}
.showParkListBox {
  /* display: flex; */
}

.parentName {
  margin-right: 10px;
  /* text-align: right; */
}
.showChildBox {
  /* display: flex; */
  padding-left: 20px;
}
.checkBox {
  /* display: flex; */
  /* align-items: center; */
  margin-left: 10px;
  display: inline;
}
.checkLabel {
  max-width: 182px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
}
.labelDisabled {
  color: #c0c4cc;
  cursor: not-allowed;
}
.labelChecked {
  color: #0f6eff;
}
.selected >>> .el-form-item__label:before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
</style>
