var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content bgFFF" }, [
      _c("h2", { staticClass: "title" }, [
        _c("div", { staticClass: "title_icon" }),
        _vm._v(" " + _vm._s(_vm.isEdit ? "修改" : "添加") + "人员 "),
      ]),
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { width: "330px" },
              attrs: {
                "label-position": "right",
                "label-width": "110px",
                model: _vm.formInline,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.full_name"),
                    prop: "pdaManagerName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: 10, placeholder: "请输入姓名" },
                    model: {
                      value: _vm.formInline.pdaManagerName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "pdaManagerName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.pdaManagerName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("searchModule.sex"), prop: "sex" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { filterable: "", placeholder: "" },
                      model: {
                        value: _vm.formInline.sex,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "sex",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.sex",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("男")]),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("女")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "出生年份", prop: "birthday" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "year",
                      placeholder: "选择年份",
                      "value-format": "yyyy",
                    },
                    model: {
                      value: _vm.formInline.birthday,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "birthday", $$v)
                      },
                      expression: "formInline.birthday",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Employee_ID"),
                    prop: "pdaManagerCode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20, placeholder: "输入员工编号" },
                    model: {
                      value: _vm.formInline.pdaManagerCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "pdaManagerCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.pdaManagerCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.phone_number"),
                    prop: "mobile",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: 11, placeholder: "输入手机号码" },
                    model: {
                      value: _vm.formInline.mobile,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "mobile",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.PDA_account"),
                    prop: "account",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: 20,
                      disabled: _vm.isEdit,
                      placeholder: "输入PDA账号",
                    },
                    model: {
                      value: _vm.formInline.account,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "account",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.account",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "none" },
                  attrs: {
                    label: _vm.$t("searchModule.On_duty_status"),
                    prop: "pdaManagerState",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.formInline.pdaManagerState,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "pdaManagerState", $$v)
                        },
                        expression: "formInline.pdaManagerState",
                      },
                    },
                    _vm._l(_vm.allManageState, function (value) {
                      return _c("el-option", {
                        key: value.code,
                        attrs: { label: value.name, value: value.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Merchant_affiliation"),
                    prop: "operationId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: _vm.isEdit, filterable: "" },
                      on: { change: _vm.queryParkList },
                      model: {
                        value: _vm.formInline.operationId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "operationId",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.operationId",
                      },
                    },
                    _vm._l(_vm.tenantList, function (value) {
                      return _c("el-option", {
                        key: value.operationId,
                        attrs: {
                          label: value.operationName,
                          value: value.operationId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Department_to"),
                    prop: "deptId",
                  },
                },
                [
                  _c("el-cascader", {
                    attrs: {
                      options: _vm.treeData,
                      "expand-trigger": "click",
                      filterable: "",
                      clearable: "",
                      "show-all-levels": false,
                      props: _vm.defaultProps,
                      "change-on-select": "",
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.formInline.deptId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "deptId", $$v)
                      },
                      expression: "formInline.deptId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.role"),
                    prop: "pdaManagerRole",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", size: "12" },
                      model: {
                        value: _vm.formInline.pdaManagerRole,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "pdaManagerRole",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.pdaManagerRole",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "请选择", value: "" },
                      }),
                      _vm._l(_vm.pdaList, function (value) {
                        return _c("el-option", {
                          key: value.code,
                          attrs: { label: value.name, value: value.code },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "selected",
                  staticStyle: { width: "700px" },
                  attrs: {
                    label: _vm.$t("searchModule.Choose_parking_lot"),
                    prop: "parkIds",
                  },
                },
                [
                  _c("el-transfer", {
                    attrs: {
                      titles: ["未选择", "已选择"],
                      filterable: "",
                      props: {
                        key: "parkId",
                        label: "parkName",
                      },
                      "filter-placeholder": "请输入车场名称",
                      data: _vm.parkList,
                    },
                    on: { change: (val) => _vm.changeTransferFn(val) },
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  }),
                  _vm.isSelected
                    ? _c("div", { staticClass: "el-form-item__error" }, [
                        _vm._v("请选择一个停车场"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showParkingList.length > 0,
                      expression: "showParkingList.length > 0",
                    },
                  ],
                  staticStyle: { width: "calc(100vw - 340px)" },
                  attrs: { label: "" },
                },
                [
                  _c(
                    "ul",
                    {
                      staticStyle: {
                        padding: "8px",
                        border: "1px solid #dcdcdc",
                        width: "100%",
                      },
                    },
                    _vm._l(_vm.showParkingList, function (item, index) {
                      return _c("li", { key: index }, [
                        _c("div", { staticClass: "showParkListBox" }, [
                          _c("div", { staticClass: "parentName" }, [
                            _vm._v(_vm._s(item[0].parentName) + ":"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "showChildBox" },
                            _vm._l(item, function (subItem, sIndex) {
                              return _c(
                                "div",
                                {
                                  key: sIndex,
                                  staticClass: "el-checkbox__input checkBox",
                                },
                                [
                                  _c("el-checkbox", {
                                    attrs: {
                                      disabled:
                                        subItem.parentId == "0" ||
                                        (subItem.countSize == 1 &&
                                          subItem.checked) ||
                                        subItem.disableAll,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleCheckAllChange(
                                          subItem,
                                          0,
                                          item
                                        )
                                      },
                                    },
                                    model: {
                                      value: subItem.checked,
                                      callback: function ($$v) {
                                        _vm.$set(subItem, "checked", $$v)
                                      },
                                      expression: "subItem.checked",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: subItem.parkName.length > 12,
                                          expression:
                                            "subItem.parkName.length > 12",
                                        },
                                      ],
                                      staticClass: "checkLabel",
                                      class: {
                                        labelDisabled:
                                          (subItem.checked &&
                                            subItem.parentId == "0") ||
                                          (subItem.countSize == 1 &&
                                            subItem.checked) ||
                                          subItem.disableAll,
                                        labelChecked:
                                          subItem.countSize != 1 &&
                                          subItem.checked &&
                                          subItem.parentId != "0" &&
                                          !subItem.disableAll,
                                      },
                                      attrs: { title: subItem.parkName },
                                    },
                                    [_vm._v(_vm._s(subItem.parkName))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            subItem.parkName.length < 12 ||
                                            subItem.parkName.length == 12,
                                          expression:
                                            "subItem.parkName.length < 12 || subItem.parkName.length == 12",
                                        },
                                      ],
                                      staticClass: "checkLabel",
                                      class: {
                                        labelDisabled:
                                          (subItem.checked &&
                                            subItem.parentId == "0") ||
                                          (subItem.countSize == 1 &&
                                            subItem.checked) ||
                                          subItem.disableAll,
                                        labelChecked:
                                          subItem.countSize != 1 &&
                                          subItem.checked &&
                                          subItem.parentId != "0" &&
                                          !subItem.disableAll,
                                      },
                                      attrs: { title: subItem.parkName },
                                    },
                                    [_vm._v(_vm._s(subItem.parkName))]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "child-box-wrap" },
                                    [
                                      subItem.berths &&
                                      subItem.berths.length > 0
                                        ? _c(
                                            "el-checkbox",
                                            {
                                              attrs: {
                                                indeterminate:
                                                  subItem.isIndeterminate,
                                                disabled: subItem.disableAll,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.handleCheckAllChange(
                                                    subItem,
                                                    1,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: subItem.checkAll,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    subItem,
                                                    "checkAll",
                                                    $$v
                                                  )
                                                },
                                                expression: "subItem.checkAll",
                                              },
                                            },
                                            [_vm._v("全选")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          staticClass: "child-item",
                                          attrs: { min: 1 },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleCheckedCitiesChange(
                                                subItem,
                                                subItem.checkedBerths,
                                                item
                                              )
                                            },
                                          },
                                          model: {
                                            value: subItem.checkedBerths,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                subItem,
                                                "checkedBerths",
                                                $$v
                                              )
                                            },
                                            expression: "subItem.checkedBerths",
                                          },
                                        },
                                        _vm._l(
                                          subItem.berths,
                                          function (berthItem) {
                                            return _c(
                                              "el-checkbox",
                                              {
                                                key: berthItem.berthId,
                                                attrs: {
                                                  label: berthItem.berthId,
                                                  disabled: subItem.disableAll,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(berthItem.berthCode)
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Items_received"),
                    prop: "assetsIds",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { multiple: "", filterable: "" },
                      on: { change: _vm.setAssetsIds },
                      model: {
                        value: _vm.assetsIds,
                        callback: function ($$v) {
                          _vm.assetsIds = $$v
                        },
                        expression: "assetsIds",
                      },
                    },
                    _vm._l(_vm.assetList, function (data) {
                      return _c("el-option", {
                        key: data.assetsId,
                        attrs: { label: data.assetsName, value: data.assetsId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "left",
                "margin-left": "8.5%",
                "margin-top": "40px",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "88px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.submitData },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "88px" },
                  attrs: { type: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }